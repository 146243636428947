import axios from "axios";
import dotenv from "dotenv";
dotenv.config();
const SHOP_SERVICE_URL = process.env.REACT_APP_SHOP_SERVICE_URL||"https://ng1iwe83ha.execute-api.eu-west-1.amazonaws.com/dev/meetings";
const SHOP_SERVICE_API_KEY = process.env.REACT_APP_SHOP_SERVICE_API_KEY||"ER0iyCC4298BgRZdnJlXQ78efZJfDuVf4wM1pcch";

async function GetMeetings() {
	const httpConfig = {
		headers: {
			"Content-Type": "application/json",
			"x-api-key": SHOP_SERVICE_API_KEY,
		},
		method: "GET"
	};

	console.log('Class: GetMeetings, Line 18 (cfg): ', httpConfig);
	return axios(`${SHOP_SERVICE_URL}`, httpConfig);
}

export default GetMeetings;
