import React from "react";
import "./MeetingList.css";

function MeetingSummary(props) {
    console.log("meetings in MeetingSummary is", props.meetings);
    let meetingsList = props.meetings;
    console.log("MeetingSummary : ", meetingsList[0]);
    let currentMeeting = meetingsList[0];
    let nextMeeting = meetingsList[1];
    const songTitle = getSong(currentMeeting.songNumber);

    currentMeeting["nextWeek"] = "Next week: " + nextMeeting.talkTitle + " by Bro " + nextMeeting.speaker;

    return (
        <div className="container-fluid">
            <div id="main">
                <div id="header">
                    <h2>{currentMeeting.description} </h2>
                </div>

                <div className="message">
                    <p><span>Chairman:</span> {currentMeeting.chairman} </p>
                    <p><span>Speaker:</span> {currentMeeting.speaker} <br/>({currentMeeting.congregation} )</p>
                    <p><span>Title:</span> {currentMeeting.talkTitle} </p>
                    <p><span>Song:</span> {currentMeeting.songNumber} ({songTitle}) </p>
                    <p><span>WT Reader:</span> {currentMeeting.reader} </p>
                    <p><span>Next week:</span> {nextMeeting.talkTitle} <br/>
                        by Bro {nextMeeting.speaker} ({nextMeeting.congregation}) </p>
                </div>

            </div>

            <div className="zoom">
                <h3><i className="fa fa-cog"/> Zoom</h3>
                <p><span>Host:</span> {currentMeeting.zoomHost} </p>
                <p><span>CoHost:</span> {currentMeeting.zoomCoHost} </p>
                <p><span>Start:</span> 10:00am</p>
                <p><span>Mic Check:</span> around 9:50am</p>
                <p><span>Comments: </span>Virtual hands 👋</p>
            </div>
        </div>
    );
}


function getSong(num) {

    const songs = {
        "0": "TBC",
        "1": "Jehovah’s Attributes",
        "2": "Jehovah Is Your Name",
        "3": "Our Strength, Our Hope, Our Confidence",
        "4": "“Jehovah Is My Shepherd”",
        "5": "God’s Wondrous Works",
        "6": "The Heavens Declare God’s Glory",
        "7": "Jehovah, Our Strength",
        "8": "Jehovah Is Our Refuge",
        "9": "Jehovah Is Our King!",
        "10": "Praise Jehovah Our God!",
        "11": "Creation Praises God",
        "12": "Great God, Jehovah",
        "13": "Christ, Our Model",
        "14": "Praising Earth’s New King",
        "15": "Praise Jehovah’s Firstborn!",
        "16": "Praise Jah for His Son, the Anointed",
        "17": "“I Want To”",
        "18": "Grateful for the Ransom",
        "19": "The Lord’s Evening Meal",
        "20": "You Gave Your Precious Son",
        "21": "Keep On Seeking First the Kingdom",
        "22": "The Kingdom Is in Place​—Let It Come!",
        "23": "Jehovah Begins His Rule",
        "24": "Come to Jehovah’s Mountain",
        "25": "A Special Possession",
        "26": "You Did It for Me",
        "27": "The Revealing of God’s Sons",
        "28": "Gaining Jehovah’s Friendship",
        "29": "Living Up to Our Name",
        "30": "My Father, My God and Friend",
        "31": "Oh, Walk With God!",
        "32": "Take Sides With Jehovah!",
        "33": "Throw Your Burden on Jehovah",
        "34": "Walking in Integrity",
        "35": "“Make Sure of the More Important Things”",
        "36": "We Guard Our Hearts",
        "37": "Serving Jehovah Whole-Souled",
        "38": "He Will Make You Strong",
        "39": "Make a Good Name With God",
        "40": "To Whom Do We Belong?",
        "41": "Please Hear My Prayer",
        "42": "The Prayer of God’s Servant",
        "43": "A Prayer of Thanks",
        "44": "A Prayer of the Lowly One",
        "45": "The Meditation of My Heart",
        "46": "We Thank You, Jehovah",
        "47": "Pray to Jehovah Each Day",
        "48": "Daily Walking With Jehovah",
        "49": "Making Jehovah’s Heart Glad",
        "50": "My Prayer of Dedication",
        "51": "To God We Are Dedicated!",
        "52": "Christian Dedication",
        "53": "Preparing to Preach",
        "54": "“This Is the Way”",
        "55": "Fear Them Not!",
        "56": "Make the Truth Your Own",
        "57": "Preaching to All Sorts of People",
        "58": "Searching for Friends of Peace",
        "59": "Praise Jah With Me",
        "60": "It Means Their Life",
        "61": "Forward, You Witnesses!",
        "62": "The New Song",
        "63": "We’re Jehovah’s Witnesses!",
        "64": "Sharing Joyfully in the Harvest",
        "65": "Move Ahead!",
        "66": "Declare the Good News",
        "67": "“Preach the Word”",
        "68": "Sowing Kingdom Seed",
        "69": "Go Forward in Preaching the Kingdom!",
        "70": "Search Out Deserving Ones",
        "71": "We Are Jehovah’s Army!",
        "72": "Making Known the Kingdom Truth",
        "73": "Grant Us Boldness",
        "74": "Join in the Kingdom Song!",
        "75": "“Here I Am! Send Me!”",
        "76": "How Does It Make You Feel?",
        "77": "Light in a Darkened World",
        "78": "“Teaching the Word of God”",
        "79": "Teach Them to Stand Firm",
        "80": "“Taste and See That Jehovah Is Good”",
        "81": "The Life of a Pioneer",
        "82": "“Let Your Light Shine”",
        "83": "“From House to House”",
        "84": "Reaching Out",
        "85": "Welcome One Another",
        "86": "We Must Be Taught",
        "87": "Come! Be Refreshed",
        "88": "Make Me Know Your Ways",
        "89": "Listen, Obey, and Be Blessed",
        "90": "Encourage One Another",
        "91": "Our Labor of Love",
        "92": "A Place Bearing Your Name",
        "93": "Bless Our Meeting Together",
        "94": "Grateful for God’s Word",
        "95": "The Light Gets Brighter",
        "96": "God’s Own Book​—A Treasure",
        "97": "Life Depends on God’s Word",
        "98": "The Scriptures​—Inspired of God",
        "99": "Myriads of Brothers",
        "100": "Receive Them With Hospitality",
        "101": "Working Together in Unity",
        "102": "“Assist Those Who Are Weak”",
        "103": "Shepherds​—Gifts in Men",
        "104": "God’s Gift of Holy Spirit",
        "105": "“God Is Love”",
        "106": "Cultivating the Quality of Love",
        "107": "The Divine Pattern of Love",
        "108": "God’s Loyal Love",
        "109": "Love Intensely From the Heart",
        "110": "“The Joy of Jehovah”",
        "111": "Our Reasons for Joy",
        "112": "Jehovah, God of Peace",
        "113": "Our Possession of Peace",
        "114": "“Exercise Patience”",
        "115": "Gratitude for Divine Patience",
        "116": "The Power of Kindness",
        "117": "The Quality of Goodness",
        "118": "“Give Us More Faith”",
        "119": "We Must Have Faith",
        "120": "Imitate Christ’s Mildness",
        "121": "We Need Self-Control",
        "122": "Be Steadfast, Immovable!",
        "123": "Loyally Submitting to Theocratic Order",
        "124": "Ever Loyal",
        "125": "“Happy Are the Merciful!”",
        "126": "Stay Awake, Stand Firm, Grow Mighty",
        "127": "The Sort of Person I Should Be",
        "128": "Enduring to the End",
        "129": "We Will Keep Enduring",
        "130": "Be Forgiving",
        "131": "“What God Has Yoked Together”",
        "132": "Now We Are One",
        "133": "Worship Jehovah During Youth",
        "134": "Children Are a Trust From God",
        "135": "Jehovah’s Warm Appeal: “Be Wise, My Son”",
        "136": "“A Perfect Wage” From Jehovah",
        "137": "Faithful Women, Christian Sisters",
        "138": "Beauty in Gray-Headedness",
        "139": "See Yourself When All Is New",
        "140": "Life Without End​—At Last!",
        "141": "The Miracle of Life",
        "142": "Holding Fast to Our Hope",
        "143": "Keep Working, Watching, and Waiting",
        "144": "Keep Your Eyes on the Prize!",
        "145": "God’s Promise of Paradise",
        "146": "“Making All Things New”",
        "147": "Life Everlasting Is Promised",
        "148": "Jehovah Provides Escape",
        "149": "A Victory Song",
        "150": "Seek God for Your Deliverance",
        "151": "He Will Call"
    };

    return songs[num];
}

export default MeetingSummary;