import React from "react";
import "./MeetingList.css";
import {Link} from "react-router-dom";
import styled from 'styled-components'
import {useTable} from 'react-table';

// import makeData from './makeData';

function AwayTalks(props) {
    console.log("meetings in AwayTalks is", props.meetings);
    const meetingsList = props.meetings;
    console.log("AwayTalks : ", meetingsList);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Away Talks',
                columns: [
                    {
                        Header: 'Date',
                        accessor: 'description',
                    },
                    {
                        Header: 'Speaker',
                        accessor: 'speaker',
                    },
                    {
                        Header: 'Congregation',
                        accessor: 'congregation',
                    },
                    {
                        Header: 'Talk',
                        accessor: 'talkTitle',
                    },
                ],
            },
        ],
        []
    );

    const data = React.useMemo(() => meetingsList, []);

    return (
        <div>
            <Styles>
                <Table columns={columns} data={data}/>
            </Styles>
        </div>
    );
}

function Table({columns, data}) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })

    // Render the UI for your table
    return (
        <table {...getTableProps()}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
};

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

export default AwayTalks;