import React from "react";
// import "@aws-amplify/ui/dist/style.css";
// import "bootstrap/dist/css/bootstrap.css";
import Amplify from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import GetMeetings from "./api/GetMeetings";
import ScrollToTop from "./ScrollToTop";
// import Footer from "./components/Footer";
import NavigationBar from "./components/NavigationBar";
import MeetingList from "./components/MeetingList";
import AwayTalks from "./components/AwayTalks";
import MeetingSummary from "./components/MeetingSummary";

Amplify.configure({
    Auth: {
        region: "eu-west-1",
        userPoolId: "eu-west-1_TqLCwPJDi",
        userPoolWebClientId: "2otoa6jumlcck3u72hh3aeek4v",// or 7mjr5pvuj6mat29ppl7d1vno16
    },
});

function CheckLogin() {
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();
    const [meetings, setMeetings] = React.useState();

    React.useEffect(() => {
        async function fetchData() {
            return await GetMeetings();
        }

        return onAuthUIStateChange(async (nextAuthState, authData) => {
            if (authData && authData.username) {
                const shopResponse = await fetchData();
                console.log("resp: ");
                console.log(shopResponse);
                setMeetings(shopResponse.data);
            }
            setUser(authData);
            setAuthState(nextAuthState);
        });
    }, []);

    return authState === AuthState.SignedIn && meetings ? (
        <div>
            <Router>
                <ScrollToTop>
                    <NavigationBar/>
                    <Switch>
                        <Route path="/" exact render={(props) =>
                            <MeetingList {...props} user={user} meetings={meetings.homeTalks} />} />
                        <Route path="/away" render={(props) =>
                            <AwayTalks {...props} user={user} meetings={meetings.awayTalks} />} />

                        <Route path="/next" exact render={(props) =>
                            <MeetingSummary {...props} user={user}
                                            meetings={Array.from(meetings.homeTalks)} />} />
                    </Switch>
                </ScrollToTop>
            </Router>
        </div>
    ) : (
        <AmplifyAuthenticator>
            <AmplifySignIn slot="sign-in" hideSignUp />
        </AmplifyAuthenticator>
    );
}

export default CheckLogin;
