import "./NavigationBar.css";
import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavItem, MenuItem } from 'react-bootstrap';

function NavigationBar() {
    // return (
    //     <div className="Navbar fixed-top">
    //         <div className="navbar-logo">
    //             <Link to={"/"}>
    //                 Home
    //             </Link>
    //             <Link to={"/away"}>
    //                 Away
    //             </Link>
    //         </div>
    //     </div>
    // );

    return (
        <>
            <Navbar bg="dark" variant="dark">
                <Navbar.Brand href="/">Barton Talk Schedule</Navbar.Brand>
                <Navbar.Brand href="/away">Away Talk Schedule</Navbar.Brand>
                <Navbar.Brand href="/next">Next Meeting Summary</Navbar.Brand>
                {/*<Nav className="mr-auto">*/}
                    {/*<Link to={"/"}>Home Talk Schedule</Link>*/}
                    {/*<p> - </p>*/}
                    {/*<Link to={"/away"}>Away Talk Schedule</Link>*/}
                {/*</Nav>*/}
            </Navbar>
        </>
    )
}

export default NavigationBar;